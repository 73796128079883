import React, { Component } from "react";
import Header from "../../Common/Components/Header/header.jsx";
import Donations from "../../Common/Components/DonationPanel/donationPanel.jsx";
import Footer from "../../Common/Components/Footer/footer.jsx";
import PageTitle from "../../Common/Components/PageTitle/pageTitle.jsx";

import Button from "../../Common/Components/Button/button";

import PDFLink from "../../Common/Components/PDFLink/pdfLink.jsx";

import cs from "./newAndRenewal.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NewMemberMainImg from "../../Common/assets/images/newMemberMainImg.JPG";
import SCCPOABrochure from "../../Common/assets/pdf/MASTER 2023 SCCPOA Brochure (2 sided).pdf";

var data = require("./Data/newAndRenewal");

class NewAndRenewal extends Component {
  render() {
    let { newAndRenewalParagraphs } = data;
    return (
      <div>
        <Header />
        <Container>
          <PageTitle title="SCCPOA New & Renewal Memberships" />
          <img
            className={cs.img}
            src={NewMemberMainImg}
            alt={"New Member Image"}
          />
          <br />
          <br />

          {newAndRenewalParagraphs.map((paragraph) => (
            <p>{paragraph}</p>
          ))}
         {/* <Row>
        <Col xs={12} sm={12}>
          <div className={cs.maintenance_note} style={{ padding: '10px', backgroundColor: '#f8d7da', border: '1px solid #f5c6cb', borderRadius: '5px', marginBottom: '20px' }}>
          <p style={{ margin: 0 }}>Membership signup is currently under maintenance. Please check back later.</p>
          </div>
        </Col>
        </Row> */}
          <Row>
            <Col xs={12} sm={6}>
              <div className={cs.membership_button}>
                <Button
                  redirection={"/newMemberships"}
                  text={"New Membership"}
                />
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className={cs.membership_button}>
                <Button
                  redirection={"/renewMemberships"}
                  text={"Renew Membership"}
                />
              </div>
            </Col>
          </Row> 
          <br />
          <hr />
          <Row>
            <Col sm={8}>
              <p>
                <b>
                  Please click the SCCPOA brochure below for more details about
                  the property:
                </b>
              </p>
              <PDFLink src={SCCPOABrochure} text="Brochure (pdf)" />
            </Col>
            <Col sm={4}>
              <p>
                <b> Membership questions: </b>
              </p>
              <FontAwesomeIcon icon={"envelope"} /> membership@sccpoa.org
            </Col>
          </Row>
        </Container>
        <Donations />
        <Footer />
      </div>
    );
  }
}

export default NewAndRenewal;
