import React, { Component } from "react";
import Header from "../../Common/Components/Header/header.jsx";
import Donations from "../../Common/Components/DonationPanel/donationPanel.jsx";
import Footer from "../../Common/Components/Footer/footer.jsx";
import PageTitle from "../../Common/Components/PageTitle/pageTitle.jsx";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import anniversary_celebration_pdf_img from "../../Common/assets/images/60YearAnniversary/60th_anniversary_event_pdf_img.jpg"
import cs from "./turkeyShootingEvent.module.css"
import ReactPayPal from "../../Common/Components/paypal/ReactPayPal.jsx";

class TurkeyShootingEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      targets: {
        itemName: "Targets",
        abbrevItemName: "Targets",
        price: 20,
        totalAmount: 0
      },
      isShootingInfoHidden: true
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.calculatePaypalTotalWithTax = this.calculatePaypalTotalWithTax.bind(this);
    this.paypalfees = this.paypalfees.bind(this);
    this.calculateTotal = this.calculateTotal.bind(this);
    this.getPaymentDescription = this.getPaymentDescription.bind(this);
    this.targetTotalAmount = this.targetTotalAmount.bind(this)
  };

  getPaymentDescription() {
    let {targets} = this.state
    return targets.totalAmount + targets.abbrevItemName+"$"+parseInt(targets.totalAmount)*targets.price
  }

  handleInputChange(event) {
    let {targets} = this.state
    targets.totalAmount = parseInt(event.target.value) || 0
    this.setState({targets});
  }

  toggleViewingShootingInfo(){
    this.setState({ isShootingInfoHidden: !this.state.isShootingInfoHidden });
  }

  paypalfees(amount) {
    amount += 0.49;
    return amount / (1 - 0.0349);
  }

  calculateTotal() {
    let {targets} = this.state
    let totalPrice = parseInt(targets.totalAmount)*parseInt(targets.price)
    return totalPrice;
  }

  calculatePaypalTotalWithTax(total) {
    return Math.round(this.paypalfees(total) * 100) / 100;
  }

  toTwoDecimal(num) {
    return parseFloat(num.toString()).toFixed(2);
  }

  targetTotalAmount(){
    return parseInt(this.state.targets.totalAmount)
  }

  render() {
    let {targets} = this.state

    return (
      <div>
        <Header />
        <Container>
          <PageTitle title="Turkey Shooting Event" />
          <p>
            The SCCPOA invites you to celebrate our 60th anniversary on Saturday May 10th 2025 from 9-2pm at 24090 Deer Path Rd., Saratoga.
            This will be a fun afternoon, delicious food, games and more! This is an exclusive event for our members and their guests/sponsors.
            If you plan to attend/participate, please select the items you would like to purchase.
          </p>

          <Row>
            <Col sm={12} md={8}>
              <div className={cs.event_options_container}>
                <div className={cs.event_options_header}> Turkey Shooting Event </div>
                <div className={cs.hiddenTextToggle} onClick={this.toggleViewingShootingInfo.bind(this)}>
                  {this.state.isShootingInfoHidden ? "See" : "Hide"} additional information {this.state.isShootingInfoHidden ? " here" : ""}.
                </div>
                {!this.state.isShootingInfoHidden &&
                  <div className={cs.anniversary_BBQ_pricing_container}>
                    <div>
                      <div className={cs.hiddenTextBlock}>
                        <p>
                          Range event hours: 9-11:00am.
                          <br />
                          Targets will be faced against the target holder so all the shooter will see is the blank side of the 8.5"X11" sheet of paper.  The face side will have squares with numbers, a "liner" counts for all numbers touching that line- line between 2 numbers counts for both, hit a corner and it counts for all 4.
                          <br />
                          Anything other than Iron sights, shoots from the 25 yard line.
                          <br />
                          The shooter will fire 6 rounds of ammo at each paper target. The shooter's target who's squares add up to the highest number at the end of the competition wins.
                        </p>
                        <p>
                          What you need to bring:
                          <br />
                          your own pistol along with enough ammo for 6 rounds per target
                        </p>
                      </div>
                    </div>
                  </div>
                }
                <div className={cs.event_option_sub_header}>Select the number of targets you would like to purchase to compete in the event:</div>
                <div style={{margin:"10px 0px 80px 0px"}}>
                  Targets $20 EACH
                  <br />
                  <input
                    type="number"
                    placeholder="enter amount"
                    value={targets.totalAmount}
                    min={0}
                    onChange={(e) => this.handleInputChange(e, 'targets', null)}
                  />
                </div>

                {this.targetTotalAmount()>0 &&
                  <div>
                    <h4 className={cs.payment_total}>Payment</h4>
                    <hr/>
                    <Row>
                      <Col>
                        <ReactPayPal
                          calculateTotal={() => this.calculatePaypalTotalWithTax( this.calculateTotal() )}
                          getPaymentDescription={this.getPaymentDescription}
                          toTwoDecimal={this.toTwoDecimal}
                        />
                      </Col>
                      <Col>
                        <h4 className={cs.header}>
                          If paid by check ($
                          {this.toTwoDecimal( this.calculateTotal() )})
                        </h4>
                        <h4 className={cs.header}>Remit payment to:</h4>
                        <p className={cs.check_content}>
                          Santa Clara County Peace Officer's Association
                          <br />
                          P.O. Box 4629
                          <br />
                          Mountain View, Ca 94040
                        </p>
                      </Col>
                    </Row>
                  </div>
                }
              </div>
            </Col>   
            <Col sm={12} md={4}>
              <div className={cs.pdf_container}>
                <div className={cs.flier_heading_text}>60th Anniversary Flier</div>
                <a href="assets/pdf/anniversaryEvent/60thAnniversaryEvent.pdf">
                  <img className={cs.pdf_img} src={anniversary_celebration_pdf_img} />
                </a>
              </div>          
            </Col>      
          </Row> 

          <br />
          <p>
            <b> SCCPOA Membership Refund/Cancellation Policy </b>{" "}
            membership dues are non-refundable and memberships are
            non-transferable. The Santa Clara County Peace Officers'
            Association reserves the right to refuse/cancel a membership
            in the SCCPOA. If SCCPOA refuses a new or renewing
            membership, registrants will be offered a refund.{" "}
            <b> Information Collected:</b> Our website does not
            automatically collect any individually identifiable
            information.{" "}
            <b>
              Your privacy is important to us, and we are committed to
              protecting it. We will never disclose or share your
              personal information without your express written consent.
            </b>{" "}
            Secure Checkout is provided through PayPal. PayPal uses the
            latest in data encryption and anti-fraud technology to keep
            your information secure, reducing the risk of online fraud.
          </p>

        </Container>
        <Donations />
        <br />
        <Footer />
      </div>
    );
  }
}

export default TurkeyShootingEvent;
