import React, { Component } from "react";
import cs from "../membership.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import shirtImg from "../../../assets/images/merchandise/tshirt.jpg";
import hatImg from "../../../assets/images/merchandise/hat.jpg";


class AnniversaryCelebration extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleTshirtAmountChange(selectedTshirtData, e){
    let newCartDetails = this.props.cartDetails
    newCartDetails.merchandiseDetails.tshirtOptions = newCartDetails.merchandiseDetails.tshirtOptions.map(tshirtData=>{
      if(tshirtData.itemName===selectedTshirtData.itemName){
        let numshirtsSelected = e.target.value>=0 ? e.target.value : 0
        tshirtData.totalAmount = numshirtsSelected
      }
      return tshirtData
    })
    this.props.setParentState({cartDetails:newCartDetails})
  }

  handleHatAmountChange(e){
    let newCartDetails = this.props.cartDetails
    let numHatsSelected = e.target.value>=0 ? e.target.value : 0
    newCartDetails.merchandiseDetails.hatDetails.totalAmount = numHatsSelected
    this.props.setParentState({cartDetails:newCartDetails})
  }

  render() {
    let {merchandiseDetails} = this.props.cartDetails

    return (
      <div>
        <p className={cs.step_header}> Merchandise </p>
        <p> Merchandise to be picked up at the 60th Anniversary event, the SCCPOA board meeting, work party, or at a scheduled time. </p>
       
        <Row>
          <Col sm={12} md={7}>
            <div className={cs.merch_img_container}>
              <img className={cs.merch_img} src={shirtImg} />
            </div>
            <div className={cs.merch_title}>Adult Size T-shirts - $25</div> 

            <div className={cs.tshirt_pricing_options_container}>
              {merchandiseDetails.tshirtOptions.map(tshirtData=>{
                return (
                  <div className={cs.tshirt_pricing_container}>
                    <div className={cs.tshirt_size}> {tshirtData.itemName} </div>
                    <div><input type="number" value={tshirtData.totalAmount} onChange={this.handleTshirtAmountChange.bind(this, tshirtData)} className={cs.merch_input}></input></div>
                  </div>
                )
              })}
            </div>

          </Col>    
          <Col sm={12} md={5}>
            <div className={cs.merch_img_container}>
              <img className={cs.merch_img} src={hatImg} />
            </div>
            <div className={cs.merch_title}>Hat - $25</div> 

            <div className={cs.tshirt_pricing_options_container}>
              <div className={cs.tshirt_pricing_container}>
                <div className={cs.tshirt_size}> {merchandiseDetails.hatDetails.itemName} </div>
                <div><input type="number" value={merchandiseDetails.hatDetails.totalAmount} onChange={this.handleHatAmountChange.bind(this)} className={cs.merch_input}></input></div>
              </div> 
            </div>
          </Col>      
        </Row> 

      </div>
    );
  }
}

export default AnniversaryCelebration;
