import React, { Component } from "react";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import cs from "../membership.module.css";
import InsuranceBeneficiaryForm from "../../../assets/pdf/2023 remit card  3 x 5 card Invoice & AD&D Ins (Back side).pdf";
import InsuranceSummary from "../../../assets/pdf/AD&D insurance summary.pdf";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PDFLink from "../../PDFLink/pdfLink.jsx";

class ADDInsurance extends Component {
  constructor(props) {
    super(props);

    this.handleADDYesCheckboxChange =
      this.handleADDYesCheckboxChange.bind(this);
    this.handleADDNoCheckboxChange = this.handleADDNoCheckboxChange.bind(this);
  }

  handleADDYesCheckboxChange(event) {
    let cartDetails = this.props.cartDetails;

    cartDetails.addDetails = {isSelected:true, isDisabled:false, price: 15};
    let totalPrice = this.props.calculateTotal();

    this.props.setParentState({
      cartDetails,
      addContinueDisabeled: false,
      totalValue: totalPrice
    });
  }

  handleADDNoCheckboxChange(event) {
    let cartDetails = this.props.cartDetails;
    cartDetails.addDetails = {isSelected:false, isDisabled:false, price: 0};
    let totalPrice = this.props.calculateTotal();
    this.props.setParentState({
      cartDetails,
      addContinueDisabeled: false,
      totalValue: totalPrice
    });
  }

  render() {
    return (
      <div>
        <span className={cs.numbered_info}>
          <p className={cs.step_header}>
            Optional - AD&D (Accidental Death and Dismemberment Insurance),
            Priced at $15/year:
          </p>
          <p>
            Purchased Only Between October 1st – March 30th Annually. Optional
            insurance is valued at $15,000. If you wish to purchase optional
            AD&D insurance, you must fill out, sign, and mail the "AD&D
            Insurance Beneficiary Form" linked below. For more information
            please click on the "Insurance Summary" pdf form.
          </p>
          <Row className={cs.payments_container}>
            <Col sm={12} md={6}>
              <PDFLink src={InsuranceBeneficiaryForm} text="AD&D Insurance Beneficiary Form (pdf)"/>
            </Col>
            <Col sm={12} md={6}>
              <PDFLink src={InsuranceSummary} text="AD&D Insurance Summary (pdf)"/>
            </Col>
          </Row>
          <div>
            <label className={cs.document_label}>
              <input
                type="radio"
                name="addGroup"
                defaultChecked={false}
                onChange={this.handleADDYesCheckboxChange}
              />
            </label>
            <span>
              Yes, I would like to purchase AD&D insurance coverage for
              $15/year.
            </span>
          </div>
          <div>
            <label className={cs.document_label}>
              <input type="radio" name="addGroup" onChange={this.handleADDNoCheckboxChange}/>
            </label>
            <span>No, I would not like to participate at this time.</span>{" "}
          </div>
        </span>
      </div>
    );
  }
}

export default ADDInsurance;
