import React from "react";
import cs from "./YouTubeEmbed.module.css";


const YouTubeEmbed = ({ videoId }) => {
  return (
    <div className={cs.videoResponsive}>
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeEmbed;