import React, { Component } from "react";
import Header from "../../Common/Components/Header/header.jsx";
import Donations from "../../Common/Components/DonationPanel/donationPanel.jsx";
import Footer from "../../Common/Components/Footer/footer.jsx";
import PageTitle from "../../Common/Components/PageTitle/pageTitle.jsx";
import Container from "react-bootstrap/Container";


class Merchandise extends Component {
  render() {
    return (
      <div>
        <Header />
        <Container>
          <PageTitle title="Merchandise" />

        </Container>
        <Donations />
        <br />
        <Footer />
      </div>
    );
  }
}

export default Merchandise;
