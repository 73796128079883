import React, { Component } from "react";
import cs from "../membership.module.css";
import InsuranceBeneficiaryForm from "../../../assets/pdf/2023 remit card  3 x 5 card Invoice & AD&D Ins (Back side).pdf";
import ApplicationForm from "../../../assets/pdf/MASTER membership application.pdf";
import Receipt from "./receipt.jsx";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PDFLink from "../../PDFLink/pdfLink.jsx";

class OrderSummary extends Component {
  toTwoDecimal(num) {
    return parseFloat(num.toString()).toFixed(2);
  }

  render() {
    let {
      cartDetails,
      memberType,
      calculateTotal,
      handleAgencyCheckAgreement,
      handleMembershipRead,
      addInsuranceAvailable
    } = this.props;

    return (
      <span className={cs.numbered_info}>
        <h2 className={cs.step_header}>Order Summary</h2>
        <Receipt
          cartDetails={cartDetails}
          subtotal={calculateTotal()}
          toTwoDecimal={this.toTwoDecimal}
          addInsuranceAvailable={addInsuranceAvailable}
        />
        <h3 className={cs.task_header}>
          Confirm the following tasks are completed to continue to payment:
        </h3>
        <div className={cs.agreement_conatainer}>
          <div>
            <input
              type="checkbox"
              defaultChecked={false}
              onChange={handleMembershipRead}
            />
          </div>
          <div className={cs.agreement}>
            Yes, I have submitted
            {cartDetails.membershipDetails.description === "*Other" && " proof of my firearms Training (Post, California BSIS, ETC), "}
            {memberType === "new" && " a copy of my Law enforcement agency/ department issued photo ID card (front and back side), and "}
            {cartDetails.membershipDetails.description === "*Other" && memberType === "renew" && "and "} {" "}
            all of the documents below either by email (secretary@sccpoa.org) or by US mail (SCCPOA, PO Box 4629, Mt. View, CA 94040).
          </div>
        </div>
        <Row className={cs.agreement_pdfs}>
          <Col md={4}>
            <PDFLink
              src={ApplicationForm}
              text={
                memberType === "new"
                  ? "Membership Application Form (pdf)"
                  : "Update Contact Information (Optional)"
              }
            />
          </Col>
          <Col md={4}>
            <PDFLink
              src="assets/pdf/RangeUseAgreement24.pdf"
              text="Range HHA (pdf)"
            />
          </Col>
          {cartDetails.addDetails.isSelected && addInsuranceAvailable && (
            <Col md={4}>
              <PDFLink
                src={InsuranceBeneficiaryForm}
                text="AD&D Insurance Beneficiary Form (pdf)"
              />
            </Col>
          )}
        </Row>
        <div className={cs.agreement_conatainer}>
          <div>
            <input
              type="checkbox"
              defaultChecked={false}
              onChange={handleAgencyCheckAgreement}
            />
          </div>
          <div className={cs.agreement}>
            I hereby authorize the SCCPOA to confirm and verify my Department
            status as a bonafide Police Agent or Officer for the Agency listed
            above. I release any individual, organization or agency from any and
            all liability incurred as a result of providing such information.
          </div>
        </div>
      </span>
    );
  }
}

export default OrderSummary;
