import React, { Component } from "react";
import Tab from "react-bootstrap/Tab";
import cs from "./membership.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import MembershipTab from "./components/membershipTab.jsx";
import RequiredDocumentsTab from "./components/requiredDocumentsTab.jsx";
import AddInsuranceTab from "./components/addInsuranceTab.jsx";
import DonationTab from "./components/donationTab.jsx";
import OrderSummaryTab from "./components/orderSummaryTab.jsx";
import CheckoutTab from "./components/checkoutTab.jsx";
import RaffleTicketsTab from "./components/raffleTab.jsx"
import AnniversaryCelebration from "./components/anniversaryCelebration.jsx"
import Merchandise from "./components/merchandise.jsx"
import TabPane from "./components/tabPane.jsx";

var data = require("../../Data/membershipTypeDataNew.js");

class Memberships extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      totalValue: 0,
      paypalCheckout: false,
      currentSelectedMemberType: null,
      // addContinueDisabeled: true,
      documentsReadCheck: false,
      agencyAgreementCheck: false,
      raffleTicketSelected: false,
      showRaffleTicketSelector: false,
      donationSelected: false,
      showDonationSelector: false,
      isAnniversaryNextButtonDisabled: true,
      memberData: data.panels,
      otherSelected: false,
      isHidden: true,
      donationButtonDisabled: true,
      raffleButtonDisabled: true,
      cartDetails: {
        membershipDetails: {
          price: 1,
          description: ""
        },
        addDetails: {
          isDisabled: true,
          isSelected: false,
          price: 15
        },
        bbqTicketDetails:{
          memberAdult: 0
        },
        raffleTicketDetails: {
          totalTickets: 0,
          price: 100
        },
        anniversaryCelebrationDetails: {
          "ticketType": [
            {
              "itemName": "MEMBER Adult/PP",
              abbrevItemName: "M",
              "price": 25,
              "totalAmount": 0
            },
            {
              "itemName": "MEMBER's Guest/PP",
              abbrevItemName: "MG",
              "price": 25,
              "totalAmount": 0
            },
            {
              "itemName": "EVENT SPONSOR/PP",
              abbrevItemName: "SP",
              "price": 25,
              "totalAmount": 0
            },
            {
              "itemName": "YOUTH (10-18)/PP",
              abbrevItemName: "Y18",
              "price": 25,
              "totalAmount": 0
            },
            {
              "itemName": "YOUTH (4-9)/PP",
              abbrevItemName: "Y9",
              "price": 10,
              "totalAmount": 0
            },
            {
              "itemName": "UNDER 3/PP",
              abbrevItemName: "U3",
              "price": 0,
              "totalAmount": 0
            }
          ],
          "bbqLunch": [
            {
              "itemName": "Hamburger",
              abbrevItemName: "H",
              "totalAmount": 0
            },
            {
              "itemName": "Hotdog",
              abbrevItemName: "HD",
              "totalAmount": 0
            },
            {
              "itemName": "Vegan Hamburger",
              abbrevItemName: "V",
              "totalAmount": 0
            }
          ],
          targets: {
            itemName: "Targets",
            abbrevItemName: "Tgt",
            price: 20,
            totalAmount: 0
          }
        },
        donationAmount: 0,
        merchandiseDetails:{
          tshirtOptions:[
            {itemName:"Small", abbrevItemName:"S", totalAmount:0, price:25},
            {itemName:"Medium", abbrevItemName:"M", totalAmount:0, price:25},
            {itemName:"Large", abbrevItemName:"L", totalAmount:0, price:25},
            {itemName:"XLarge", abbrevItemName:"XL", totalAmount:0, price:25},
            {itemName:"2XLarge", abbrevItemName:"2XL", totalAmount:0, price:25},
            {itemName:"3XLarge", abbrevItemName:"3XL", totalAmount:0, price:25}
          ],
          hatDetails:{itemName:"Hat", abbrevItemName:"Ht", totalAmount:0, price:25}
        }
      },
      addInsuranceAvailable: true
    };

    this.handleMembershipRead = this.handleMembershipRead.bind(this);
    this.handleAgencyCheckAgreement = this.handleAgencyCheckAgreement.bind(this);
    this.handleMemberTypeClick = this.handleMemberTypeClick.bind(this);
    this.prevNextButtonClicked = this.prevNextButtonClicked.bind(this);
    this.paypalfees = this.paypalfees.bind(this);
    this.calculateTotal = this.calculateTotal.bind(this);
    this.calculatePaypalTotalWithTax = this.calculatePaypalTotalWithTax.bind(this);
    this.setParentState = this.setParentState.bind(this);
  }

  paypalfees(amount) {
    amount += 0.49;
    return amount / (1 - 0.0349);
  }

  calculateTotal() {
    const { membershipDetails, addDetails, anniversaryCelebrationDetails, raffleTicketDetails, donationAmount, merchandiseDetails } = this.state.cartDetails;
    let totalPrice =
      membershipDetails.price +
      addDetails.price * addDetails.isSelected +
      anniversaryCelebrationDetails.ticketType.reduce((total, item) => parseInt(total) + parseInt(item.totalAmount)*item.price, 0) +
      parseInt(anniversaryCelebrationDetails.targets.totalAmount)*parseInt(anniversaryCelebrationDetails.targets.price) +
      raffleTicketDetails.price * raffleTicketDetails.totalTickets +
      parseFloat(donationAmount) +
      merchandiseDetails.tshirtOptions.reduce((total, tshirtData) => parseInt(total) + parseInt(tshirtData.totalAmount)*tshirtData.price, 0) +
      parseInt(merchandiseDetails.hatDetails.totalAmount*merchandiseDetails.hatDetails.price)
    return totalPrice;
  }

  calculatePaypalTotalWithTax(total) {
    return Math.round(this.paypalfees(total) * 100) / 100;
  }

  handleMemberTypeClick(memberType, price) {
    let cartDetails = this.state.cartDetails;
    cartDetails.membershipDetails = { price, description: memberType };

    let totalPrice = this.calculateTotal();

    this.setState({
      currentSelectedMemberType: memberType,
      cartDetails,
      totalValue: totalPrice,
    });
  }

  handleMembershipRead(e) {
    this.setState({ documentsReadCheck: !this.state.documentsReadCheck });
  }

  handleAgencyCheckAgreement(e) {
    this.setState({ agencyAgreementCheck: !this.state.agencyAgreementCheck });
  }

  setTab(tab) {
    this.setState({ currentTab: tab });
  }

  prevNextButtonClicked(tab) {
    this.setTab(tab);
    window.scrollTo(0, 0);
  }

  toTwoDecimal(num) {
    return parseFloat(num.toString()).toFixed(2);
  }

  setParentState(updatedState) {
    this.setState({ ...this.state, ...updatedState });
  }

  render() {
    const {currentTab, currentSelectedMemberType, cartDetails, documentsReadCheck, agencyAgreementCheck, donationButtonDisabled, addInsuranceAvailable, isAnniversaryNextButtonDisabled, raffleButtonDisabled} = this.state;
    const { memberType } = this.props;

    let tabComponentData = [
      {
        tabLabel: "Membership",
        buttonLabel: "Membership",
        nextButtonDisabled: !currentSelectedMemberType,
        component: (
          <MembershipTab
            cartDetails={cartDetails}
            calculateTotal={this.calculateTotal}
            setParentState={this.setParentState}
            currentSelectedMemberType={currentSelectedMemberType}
            handleMemberTypeClick={this.handleMemberTypeClick}
          />
        ),
      },
      {
        tabLabel: "Documents",
        buttonLabel: "Documents",
        nextButtonDisabled: false,
        component: <RequiredDocumentsTab memberType={memberType} cartDetails={cartDetails}/>
      },
      {
        tabLabel: "AD&D Insurance",
        buttonLabel: "AD&D Insurance",
        hidden: !addInsuranceAvailable,
        nextButtonDisabled: cartDetails.addDetails.isDisabled,
        component: (
          <AddInsuranceTab
            cartDetails={cartDetails}
            calculateTotal={this.calculateTotal}
            setParentState={this.setParentState}
          />
        ),
      },
      {
        tabLabel: "60th Anniversary Celebration",
        buttonLabel: "60th Anniversary Celebrationn",
        nextButtonDisabled: isAnniversaryNextButtonDisabled ,
        component: <AnniversaryCelebration cartDetails={cartDetails} setParentState={this.setParentState}/>
      },
      {
        tabLabel: "Raffle Tickets",
        buttonLabel: "Raffle Tickets",
        nextButtonDisabled: raffleButtonDisabled,
        component: <RaffleTicketsTab cartDetails={cartDetails} setParentState={this.setParentState}/>
      },
      {
        tabLabel: "Donation",
        buttonLabel: "Donation",
        nextButtonDisabled: donationButtonDisabled,
        component: <DonationTab cartDetails={cartDetails} setParentState={this.setParentState}/>
      },
      {
        tabLabel: "Merchandise",
        buttonLabel: "Merchandise",
        component: <Merchandise cartDetails={cartDetails} setParentState={this.setParentState}/>
      },
      {
        tabLabel: "Order Summary",
        buttonLabel: "Summary",
        nextButtonDisabled: !(documentsReadCheck && agencyAgreementCheck),
        component: (
          <OrderSummaryTab
            cartDetails={cartDetails}
            memberType={memberType}
            calculateTotal={this.calculateTotal}
            handleAgencyCheckAgreement={this.handleAgencyCheckAgreement}
            handleMembershipRead={this.handleMembershipRead}
            addInsuranceAvailable={addInsuranceAvailable}
          />
        ),
      },
      {
        tabLabel: "Checkout",
        buttonLabel: "Payment",
        nextButtonDisabled: false,
        component: (
          <CheckoutTab
            cartDetails={cartDetails}
            calculateTotal={this.calculateTotal}
            calculatePaypalTotalWithTax={this.calculatePaypalTotalWithTax}
            toTwoDecimal={this.toTwoDecimal}
          />
        ),
      },
    ];
    tabComponentData = tabComponentData.filter(compData=>!compData.hidden)

    return (
      <Tab.Container activeKey={currentTab}>
        <Row className={cs.checkout_container}>
          <Col sm={12}>
            <Nav className="flex-row">
              {tabComponentData.map((compData, index) => {
                return (
                  <Nav.Item className={cs.nav_item} key={index}>
                    <Nav.Link eventKey={index} className={currentTab >= index ? cs.active_nav_link : cs.nav_link}
                      onClick={this.setTab.bind(this, index)}
                      disabled={index > currentTab}
                    >
                      {compData.tabLabel}
                    </Nav.Link>
                    {index < tabComponentData.length - 1 && (
                      <hr className={currentTab > index ? cs.completed_progress_line : cs.incompleted_progress_line
                    }
                      />
                    )}
                  </Nav.Item>
                );
              })}
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content className={cs.tab_content}>
              {tabComponentData.map((compData, i) => {
                const prevButtonLabel = i > 0 ? tabComponentData[i - 1].buttonLabel : null;
                const nextButtonLabel = i < tabComponentData.length - 1 ? tabComponentData[i + 1].buttonLabel : null;
                return (
                  <TabPane
                    key={i}
                    index={i}
                    prevButtonLabel={prevButtonLabel}
                    nextButtonLabel={nextButtonLabel}
                    prevNextButtonClicked={this.prevNextButtonClicked}
                    nextButtonDisabled={compData.nextButtonDisabled}
                  >
                    {compData.component}
                  </TabPane>
                );
              })}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );

  }
}

export default Memberships;
