import React, { Component } from "react";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import cs from "../membership.module.css";
import MemberTypePanel from "../../MemberTypePanelRow/memberTypePanel.jsx";
var data = require("../../../Data/membershipTypeDataNew.js");


class MembershipTab extends Component {
    constructor(props) {
    super(props);
    this.state = {
      memberData: data.panels
    }
    this.membershipNumChanged = this.membershipNumChanged.bind(this);
  }

  membershipNumChanged(membershipIdx, e) {
    let numMembershipsSelected = e.target.value;
    let memberData = this.state.memberData;
    memberData[membershipIdx].numOfMemberships = numMembershipsSelected;
    this.props.setParentState({ memberData });
  }

  render() {
    return (
      <span className={cs.numbered_info}>
        <p className={cs.step_header}>
          Please select the membership you would like to purchase
          below.
        </p>
        {this.state.memberData.map((panel, i) => (
          <MemberTypePanel
            key={i}
            idx={i}
            currentSelectedMemberType={this.props.currentSelectedMemberType}
            handleMemberTypeClick={this.props.handleMemberTypeClick}
            data={panel}
            withPaypal={true}
            numOfMemberships={0}
            membershipNumChanged={this.membershipNumChanged}
          />
        ))}
      </span>
    );
  }
}

export default MembershipTab;




