import React, { Component } from "react";

import logo from "./logo.svg";
import "./App.css";
import Home from "./views/Home/home.jsx";
import About from "./views/About/about.jsx";
import Appreciation from "./views/Appreciation/appreciation.jsx";
import DirectDonationsSCCPOA from "./views/DirectDonationsSCCPOA/directDonationsSCCPOA.jsx";
import DirectDonationsSCCPOF from "./views/DirectDonationsSCCPOF/directDonationsSCCPOF.jsx";
import Campground from "./views/Campground/campground.jsx";
import CharitableFoundation from "./views/CharitableFoundation/charitableFoundation.jsx";
import BoardMembers from "./views/BoardMembers/boardMembers.jsx";
import CharitableFundraising from "./views/CharitableFundraising/charitableFundraising.jsx";
import CharitableFundraisingVIP from "./views/CharitableFundraising/charitableFundraisingVIP.jsx";
import NewAndRenewal from "./views/NewAndRenewal/newAndRenewal.jsx";
import NewMemberships from "./views/NewMemberships/newMemberships.jsx";
import ADDInsurance from "./views/ADDInsurance/addInsurance.jsx";
import Contact from "./views/Contact/contact.jsx";
import Range from "./views/Range/range.jsx";
import ReactPayPal from "./Common/Components/paypal/ReactPayPal.jsx";

import CampgroundReservation from "./views/CampgroundReservation/campgroundReservation.jsx";
import LetterFromThePresident from "./views/LetterFromThePresident/letterFromThePresident";
import YouthOutreach from "./views/YouthOutreach/youthOutreach.jsx"
import Raffle from "./views/Raffle/raffle";
import AnniversaryEvent from "./views/AnniversaryEvent/anniversaryEvent.jsx";
import Merchandise from "./views/Merchandise/merchandise.jsx";
import PdfQrCodeRedirect from "./views/PDF-qr-code-redirect/pdf-qr-code-redirect";

import RenewMemberships from "./views/RenewMemberships/renewMemberships";
import RoadConditions from "./views/RoadConditions/roadConditions";
import GiftCertificateMemberships from "./views/GiftCertificateMemberships/giftCertificateMemberships";
import Volunteer from "./views/Volunteer/volunteer.jsx";
import CorporateCharitableFundraising from "./views/CharitableFundraising/corporateCharitableFundraising.jsx";
import TurkeyShootingEvent from "./views/TurkeyShootingEvent/turkeyShootingEvent.jsx";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope,
  faKey,
  faCalendarAlt,
  faPhoneAlt,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

library.add(
  faEnvelope,
  faKey,
  faFacebookF,
  faFilePdf,
  faCalendarAlt,
  faPhoneAlt,
  faCircle
);

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/aboutSCCPOF">
              <DirectDonationsSCCPOF title={"About the SCCPOF"}/>
            </Route>
            <Route path="/charitableFundraising">
              <CharitableFundraising />
            </Route>
            <Route path="/corporate-charitable-fundraising">
              <CorporateCharitableFundraising />
            </Route>
            <Route path="/charitable-fundraising-vip">
              <CharitableFundraisingVIP />
            </Route>
            
            {/* <Route path="/charitableFoundation">
              <CharitableFoundation />
            </Route>  */}
            <Route path="/users"></Route>
            <Route path="/directDonationsSCCPOA">
              <DirectDonationsSCCPOA />
            </Route>
            <Route path="/directDonationsSCCPOF">
              <DirectDonationsSCCPOF title={"Direct Donations, SCCPOF"}/>
            </Route>
            <Route path="/appreciation">
              <Appreciation />
            </Route>
            <Route path="/boardMembers">
              <BoardMembers />
            </Route>
            <Route path="/Campground">
              <Campground />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/ThisIsForBudAndBudsEyesOnlyNewMembership">
              <NewMemberships />
            </Route>
            <Route path="/addInsurance">
              <ADDInsurance />
            </Route>
            <Route path="/newAndRenewal">
              <NewAndRenewal />
            </Route>
            <Route path="/newMemberships">
              <NewMemberships />
            </Route>
            <Route path="/letterFromThePresident">
              <LetterFromThePresident />
            </Route>
            <Route path="/CampgroundReservation">
              <CampgroundReservation />
            </Route>
            <Route path="/renewMemberships">
              <RenewMemberships />
            </Route>
            <Route path="/roadConditions">
              <RoadConditions />
            </Route>
            <Route path="/range">
              <Range />
            </Route>
            <Route path="/paypal">
              <ReactPayPal />
            </Route>
            <Route path="/volunteer">
              <Volunteer/>
            </Route>
            <Route path="/60th.anniversary.event">
              <AnniversaryEvent/>
            </Route> 
            <Route path="/turkeyShootingEvent">
              <TurkeyShootingEvent/>
            </Route> 
            <Route path="/raffle">
              <Raffle/>
            </Route>
            <Route path="/merchandise">
              <Merchandise/>
            </Route>
            <Route exact path="/static/media/MASTER-SCCPOA-membership-application.186cb790.pdf">
              <PdfQrCodeRedirect />
            </Route>
            <Route path="/youthOutreach">
              <YouthOutreach />
            </Route>
            <Route path="/giftCertificateMemberships">
              <GiftCertificateMemberships />
            </Route>
            
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
