import React, { Component } from "react";
import cs from "../membership.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import anniversaryEventPdfImg from "../../../assets/images/60YearAnniversary/60th_anniversary_event_pdf_img.jpg";


class AnniversaryCelebration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
      showPricingOptions: false,
      errorMessage:"",
      isShootingInfoHidden: true
    };

    this.handleYesCheckboxChange = this.handleYesCheckboxChange.bind(this);
    this.handleNoCheckboxChange = this.handleNoCheckboxChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  toggleTextBlockHidden() {
    this.setState({ isHidden: !this.state.isHidden });
  }

  handleYesCheckboxChange(event) {
    let cartDetails = this.props.cartDetails;
    cartDetails.bbqTicketDetails.memberAdult = 1
    this.setState({showPricingOptions:true});
    this.props.setParentState({ isAnniversaryNextButtonDisabled:!this.hasValidationError(cartDetails.anniversaryCelebrationDetails) });
  }

  handleNoCheckboxChange(event) {
    let cartDetails = this.props.cartDetails;
    cartDetails.anniversaryCelebrationDetails.ticketType.forEach(item => item.totalAmount = 0);
    cartDetails.anniversaryCelebrationDetails.bbqLunch.forEach(item => item.totalAmount = 0);
    cartDetails.anniversaryCelebrationDetails.targets.totalAmount = 0;
    this.setState({ showPricingOptions: false });
    this.props.setParentState({cartDetails, isAnniversaryNextButtonDisabled:false });
  }

  hasValidationError(anniversaryCelebrationDetails){
    let totalTickets = anniversaryCelebrationDetails.ticketType.reduce((acc, item) => acc + item.totalAmount, 0);
    const totalLunchItems = anniversaryCelebrationDetails.bbqLunch.reduce((acc, item) => acc + item.totalAmount, 0);
    return totalTickets !== totalLunchItems || totalTickets <= 0;
  }

  handleInputChange(event, category, index) {
    let newAnniversaryCelebrationDetails = this.props.cartDetails.anniversaryCelebrationDetails;
    let value = parseInt(event.target.value) || 0
    let errorMessage = "";
    let isError = false;

    if (category === 'bbqLunch') {
      // Calculate the total number of tickets
      let totalTickets = newAnniversaryCelebrationDetails.ticketType.reduce((acc, item) => acc + item.totalAmount, 0);
      // Calculate the total number of lunch items
      let totalLunchItems = newAnniversaryCelebrationDetails.bbqLunch.reduce((acc, item) => acc + item.totalAmount, 0);

      // If the new value exceeds the total number of tickets, set it to the remaining number of tickets
      if (totalLunchItems + value - newAnniversaryCelebrationDetails.bbqLunch[index].totalAmount > totalTickets) 
        value = totalTickets - totalLunchItems + newAnniversaryCelebrationDetails.bbqLunch[index].totalAmount
    }

    if (index !== null) 
      newAnniversaryCelebrationDetails[category][index].totalAmount = value;
    else 
      newAnniversaryCelebrationDetails[category].totalAmount = value;
    
    // Check if total tickets and total lunch items are equal
    if (this.hasValidationError(newAnniversaryCelebrationDetails)) {
      errorMessage = "The total amount of tickets must be equal to the total amount of lunch items selected and must be greater than 0.";
      isError = true;
    }
    
    this.props.setParentState({ anniversaryCelebrationDetails: newAnniversaryCelebrationDetails, isAnniversaryNextButtonDisabled:isError });
    this.setState({ errorMessage });
  }

  toggleViewingShootingInfo(){
    this.setState({ isShootingInfoHidden: !this.state.isShootingInfoHidden });
  }

  render() {
    let { showPricingOptions, errorMessage } = this.state;
    let { anniversaryCelebrationDetails } = this.props.cartDetails;

    // Inline styling for the error message
    const errorMessageStyle = {
      color: 'red',
      backgroundColor: '#f8d7da',
      padding: '10px',
      borderRadius: '5px',
      marginTop: '10px',
      textAlign: 'center',
      marginBottom: '30px'
    };

    return (
      <div>
        <Row>
          <Col sm={12} md={8}>
            <p className={cs.step_header}>
              SCCPOA 60th Anniversary Celebration
            </p>
            <div>
              <p>
                The SCCPOA invites you to celebrate our 60th anniversary on Saturday May 10th 2025 from 9-2pm at 24090 Deer Path Rd., Saratoga.
                This will be a fun afternoon, delicious food, games and more! This is an exclusive event for our members and their guests/sponsors.
                If you plan to attend/participate, please select the items you would like to purchase.
              </p>

              <div className={cs.yes_no_checkbox_containers}>
                <label className={cs.document_label}>
                  <input type="radio" name="raffleGroup" onChange={this.handleYesCheckboxChange} />
                </label>
                <span>Yes, I would like to purchase an admission ticket.</span>
              </div>

              {showPricingOptions &&
                <div className={cs.yes_options_container}>
                  <div className={cs.yes_option_header}>Admission &amp; BBQ lunch</div>
                  <div className={cs.yes_option_sub_header}>Enter the number of persons attending:</div>
                  <div className={cs.anniversary_BBQ_pricing_container}>
                    {anniversaryCelebrationDetails.ticketType.map((item, index) => (
                      <div key={index} className={cs.anniversary_BBQ_pricing_item}>
                        <div>{item.itemName} - {item.price === 0 ? 'FREE' : '$' + item.price}</div>
                        {item.itemName!=="UNDER 3/PP" &&
                          <div>
                            <input
                              type="number"
                              placeholder="enter amount"
                              value={item.totalAmount}
                              min={0}
                              onChange={(e) => this.handleInputChange(e, 'ticketType', index)}
                            />
                          </div>
                        }
                      </div>
                    ))}
                  </div>

                  <div className={cs.yes_option_sub_header}>
                    Select your lunch entre for the BBQ:
                  </div>
                  <div className={cs.yes_option_description}>
                    Lunch uncludes entre, salad, chips, beans, drink & dessert
                    <br/>
                    (Rastelli's VBites Plant-Based Vegan Meat)
                  </div>
                  <div className={cs.anniversary_BBQ_pricing_container}>
                    {anniversaryCelebrationDetails.bbqLunch.map((item, index) => (
                      <div key={index} className={cs.anniversary_BBQ_pricing_item}>
                        <div>{item.itemName}</div>
                        <div>
                          <input
                            type="number"
                            placeholder="enter amount"
                            value={item.totalAmount}
                            min={0}
                            onChange={(e) => this.handleInputChange(e, 'bbqLunch', index)}
                            className={errorMessage?cs.lunch_error_inputs:""}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  {errorMessage && <div style={errorMessageStyle}>{errorMessage}</div>}

                  <div className={cs.yes_option_header}> Turkey Shooting Event </div>
                  <div className={cs.hiddenTextToggle} onClick={this.toggleViewingShootingInfo.bind(this)}>
                    {this.state.isShootingInfoHidden ? "See" : "Hide"} additional information {this.state.isShootingInfoHidden ? " here" : ""}.
                  </div>
                  {!this.state.isShootingInfoHidden &&
                    <div className={cs.anniversary_BBQ_pricing_container}>
                      <div>
                        <div className={cs.hiddenTextBlock}>
                          <p>
                            Range event hours: 9-11:00am.
                            <br />
                            Targets will be faced against the target holder so all the shooter will see is the blank side of the 8.5"X11" sheet of paper.  The face side will have squares with numbers, a "liner" counts for all numbers touching that line- line between 2 numbers counts for both, hit a corner and it counts for all 4.
                            <br />
                            Anything other than Iron sights, shoots from the 25 yard line.
                            <br />
                            The shooter will fire 6 rounds of ammo at each paper target. The shooter's target who's squares add up to the highest number at the end of the competition wins.
                          </p>
                          <p>
                            What you need to bring:
                            <br />
                            your own pistol along with enough ammo for 6 rounds per target
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                  <div className={cs.yes_option_sub_header}>Select the number of targets you would like to purchase to compete in the event:</div>
                  <div style={{margin:"10px 0px 80px 0px"}}>
                    Targets $20 EACH
                    <br />
                    <input
                      type="number"
                      placeholder="enter amount"
                      value={anniversaryCelebrationDetails.targets.totalAmount}
                      min={0}
                      onChange={(e) => this.handleInputChange(e, 'targets', null)}
                    />
                  </div>
                </div>
              }
              <div className={cs.yes_no_checkbox_containers}>
                <label className={cs.document_label}>
                  <input type="radio" name="raffleGroup" onChange={this.handleNoCheckboxChange} />
                </label>
                <span>No, I do not plan to attend.</span>
              </div>

            </div>
          </Col>
          <Col sm={12} md={4}>
            <div className={cs.pdf_img_container}>
              <a href="assets/pdf/anniversaryEvent/60thAnniversaryEvent.pdf" target="_blank">
                <img className={cs.pdf_img} src={anniversaryEventPdfImg} />
              </a>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AnniversaryCelebration;
