import React, { Component } from "react";
import cs from "../membership.module.css";

class Receipt extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.calculateAnniversaryAdmissionTicketsPrice = this.calculateAnniversaryAdmissionTicketsPrice.bind(this)
  }
  calculatePaypalFees(total) {
    let totalFees = total * 0.0349 + 0.49;
    return Math.round(totalFees * 100) / 100;
  }
  calculatePaypalTotalWithTax(total) {
    let totalAmount = total * 1.0349 + 0.49;
    return Math.round(totalAmount * 100) / 100;
  }

  calculateAnniversaryAdmissionTicketsPrice(){
    return this.props.cartDetails.anniversaryCelebrationDetails.ticketType.reduce((total, item) => parseInt(total) + parseInt(item.totalAmount)*item.price, 0)
  }

  render() {
    let {
      cartDetails: {
        membershipDetails,
        addDetails,
        raffleTicketDetails,
        donationAmount,
        merchandiseDetails,
        anniversaryCelebrationDetails
      },
      subtotal,
      toTwoDecimal,
      addInsuranceAvailable
    } = this.props;

    let numMerchandiseItemsPurchased = merchandiseDetails.tshirtOptions.reduce((total, tshirtData) => parseInt(total) + parseInt(tshirtData.totalAmount), 0) + parseInt(merchandiseDetails.hatDetails.totalAmount)
    let numAnniversaryAdmissionTicketsPurchased = anniversaryCelebrationDetails.ticketType.reduce((total, item) => parseInt(total) + parseInt(item.totalAmount), 0)
    let numtargetsPurchased = anniversaryCelebrationDetails.targets.totalAmount

    return (
      <div className={cs.receipt_container}>
        <div className={cs.receipt}>

          {/* Description Column*/}
          <div className={cs.receipt_col}>
            <div className={cs.receipt_header}> Description </div>
            <div className={cs.receipt_data}> {membershipDetails.description} </div>
            { addInsuranceAvailable && <div className={cs.receipt_data}> AD&D Insurance </div>} 
            {numAnniversaryAdmissionTicketsPurchased>0 && <div className={cs.receipt_data}> 60th Anniversary Admission Tickets </div> }
            {numtargetsPurchased>0 && <div className={cs.receipt_data}> Targets </div> }
            {raffleTicketDetails.totalTickets>0 && <div className={cs.receipt_data}> Raffle Tickets </div> }
            {donationAmount>0 && <div className={cs.receipt_data}> Donations </div> }
            {numMerchandiseItemsPurchased>0 && <div className={cs.receipt_data}> Merchandise </div> }
          </div>

          {/* Quantity Column */}
          <div className={cs.receipt_col}>
            <div className={cs.receipt_header}> Qty </div>
            <div className={cs.receipt_data}> 1 </div>
            { addInsuranceAvailable && <div className={cs.receipt_data}> {addDetails.isSelected ? 1 : 0} </div> }
            {numAnniversaryAdmissionTicketsPurchased>0 && <div className={cs.receipt_data}> {numAnniversaryAdmissionTicketsPurchased} </div> }
            {numtargetsPurchased>0 && <div className={cs.receipt_data}> {parseInt(numtargetsPurchased)} </div> }
            {raffleTicketDetails.totalTickets>0 && <div className={cs.receipt_data}> {raffleTicketDetails.totalTickets} </div> }
            {donationAmount>0 && <div className={cs.receipt_data}> {!!donationAmount ? 1 : 0} </div> }
            { numMerchandiseItemsPurchased>0 && <div className={cs.receipt_data}> {numMerchandiseItemsPurchased} </div> }
          </div>

          {/* Price Column */}
          <div className={cs.receipt_col}>
            <div className={cs.receipt_header}> Price </div>
            <div className={cs.receipt_data}> ${toTwoDecimal(membershipDetails.price)} </div>
            { addInsuranceAvailable && <div className={cs.receipt_data}> $15.00 </div> }
            {numAnniversaryAdmissionTicketsPurchased>0 && <div className={cs.receipt_data}> Pricing Varies </div>}  
            {numtargetsPurchased>0 && <div className={cs.receipt_data}> ${anniversaryCelebrationDetails.targets.price} </div>}    
            {raffleTicketDetails.totalTickets>0 && <div className={cs.receipt_data}> ${toTwoDecimal(raffleTicketDetails.price)}</div> }
            {donationAmount>0 && <div className={cs.receipt_data}> ${toTwoDecimal(donationAmount || 0)} </div> }
            { numMerchandiseItemsPurchased>0 && <div className={cs.receipt_data}> $25 </div> }
          </div>

          {/* Total Column */}
          <div className={cs.receipt_total}>
            <div className={cs.receipt_header}> Total </div>
            <div className={cs.receipt_data}> ${toTwoDecimal(1 * membershipDetails.price)} </div>
            { addInsuranceAvailable &&
              <div className={cs.receipt_data}> ${ toTwoDecimal((addDetails.isSelected ? 1 : 0) * addDetails.price)}</div> 
            }
            {numAnniversaryAdmissionTicketsPurchased>0 && <div className={cs.receipt_data}> ${toTwoDecimal(this.calculateAnniversaryAdmissionTicketsPrice()) || 0} </div>}
            {numtargetsPurchased>0 && <div className={cs.receipt_data}> ${toTwoDecimal( anniversaryCelebrationDetails.targets.price*numtargetsPurchased)} </div>}
            {raffleTicketDetails.totalTickets>0 && <div className={cs.receipt_data}> ${toTwoDecimal(raffleTicketDetails.totalTickets * raffleTicketDetails.price)} </div> }
            {donationAmount>0 && <div className={cs.receipt_data}> ${toTwoDecimal(donationAmount) || 0} </div> }
            {numMerchandiseItemsPurchased>0 && <div className={cs.receipt_data}> ${toTwoDecimal(numMerchandiseItemsPurchased*25) || 0} </div>}
          </div>
        </div>

        <div className={cs.receipt_subtotal}>
          {/*<div>
            <div className={cs.receipt_data}> Subtotal </div>
            <div className={cs.receipt_data}> Paypal Fees </div>
            <div className={cs.receipt_data}> Total </div>
          </div>*/}
          <div>
            <div className={cs.receipt_data}> ${toTwoDecimal(subtotal)} </div>
            {/*<div className={cs.receipt_data}> ${this.calculatePaypalFees(subtotal)} </div>
            <div className={cs.receipt_data}> ${this.calculatePaypalTotalWithTax(subtotal)} </div>*/}
          </div>
        </div>
      </div>
    );
  }
}

export default Receipt;
